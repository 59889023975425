import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import Registros from './Registros';

function Dashboard() {
    const history = useHistory();

    const handleLogout = () => {
        // Lógica para cerrar sesión

        // Redirigir a la página principal
        history.push("/");
    };

    return (
        <div>
            <Navbar className="navbar-dashboard">
                <Container>
                    <Navbar.Brand>
                        <button className="btn btn-primary">
                            <GiHamburgerMenu />
                        </button>
                    </Navbar.Brand>

                    <Navbar.Toggle />

                    <Navbar.Collapse className="justify-content-end gap-3">
                        {/* <Nav.Link href="#home">Home</Nav.Link> */}
                        <NavDropdown title={<span><FaUserCircle /></span>}>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div>
                <Router>
                    <Route path="/dashboard/registros" component={Registros} />
                </Router>
            </div>
        </div>
    );
}

export default Dashboard;
