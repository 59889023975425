import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);

    const history = useHistory();

    if (redirectToDashboard) {
        return <Redirect to="/dashboard/registros" />;
    }

    const handleSubmit = (event) => {
        event.preventDefault(); // Evita que el formulario se envíe automáticamente
        // Lógica para enviar el formulario

        if (true) {
            setRedirectToDashboard(true);
        }
    };

    return (
        <div class="login-content">
            <Container className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col className='col-6'>
                        <Card class="p-3">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Usuario</Form.Label>
                                    <Form.Control type="email" placeholder="name@example.com" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control type="password" />
                                </Form.Group>
                                <Button variant="primary" className="btn-lg w-100" type="submit">Iniciar sesión</Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Login;
