import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table, Pagination } from 'react-bootstrap';
import axios from 'axios';

function generatePagination(totalPages, currentPage, onPageChange) {
    const items = [];
    
    if (totalPages > 1) {
    items.push(
        <Pagination.Prev
        key="prev"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        />
    );

    if (totalPages <= 5) {
        for (let page = 1; page <= totalPages; page++) {
        items.push(
            <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
            >
            {page}
            </Pagination.Item>
        );
        }
    } else {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(currentPage + 2, totalPages);

        if (startPage > 1) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        for (let page = startPage; page <= endPage; page++) {
        items.push(
            <Pagination.Item
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
            >
            {page}
            </Pagination.Item>
        );
        }

        if (endPage < totalPages) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }
    }

    items.push(
        <Pagination.Next
        key="next"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        />
    );
    }

    return <Pagination>{items}</Pagination>;
}

function Registros() {
    const [usuariosRegistrados, setUsuariosRegistrados] = useState([]);
    const [paginaUsuariosRegistrados, setPaginaUsuariosRegistrados] = useState(1);
    const [totalPaginasUR, setTotalPaginasUR] = useState(1);

    const [videosAlmacenados, setVideosAlmacenados] = useState([]);
    const [paginaVideosAlmacenados, setPaginaVideosAlmacenados] = useState(1);
    const [totalPaginasVA, setTotalPaginasVA] = useState(1);

    useEffect(() => {
        fetchUsuariosRegistrados();
        fetchVideosAlmacenados();
    }, [paginaUsuariosRegistrados, paginaVideosAlmacenados]);

    const fetchUsuariosRegistrados = async () => {
        try {
        const response = await axios.get(`${process.env.REACT_APP_REST_URL}/admin/users-info?page=${paginaUsuariosRegistrados}&limit=10`);
        const data = response.data;
        setUsuariosRegistrados(data[0].results);
        setTotalPaginasUR(data[0].totalPages[0].totalPages);
        } catch (error) {
        console.error('Error al realizar la llamada a la API:', error);
        }
    };

    const fetchVideosAlmacenados = async () => {
        try {
        const response = await axios.get(`${process.env.REACT_APP_REST_URL}/admin/multimedia-info?page=${paginaVideosAlmacenados}&limit=10`);
        const data = response.data;
        setVideosAlmacenados(data[0].results);
        setTotalPaginasVA(data[0].totalPages[0].totalPages);
        } catch (error) {
        console.error('Error al realizar la llamada a la API:', error);
        }
    };

    const handlePageChangeUR = (page) => {
        setPaginaUsuariosRegistrados(page);
    };

    const handlePageChangeVA = (page) => {
        setPaginaVideosAlmacenados(page);
    };

    return (
        <div>
        <Container className="p-2">
            <Row className="mt-4">
            <Col xs={12} md={12}>
                <Card>
                <Card.Header>Usuarios registrados</Card.Header>
                <Card.Body>
                    <div className="table-container">
                    <Table responsive="xl">
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Apellidos</th>
                            <th>Correo</th>
                            <th>Fecha de nacimiento</th>
                            <th>Fecha de registro</th>
                            <th>Videos subidos</th>
                        </tr>
                        </thead>
                        <tbody>
                        {usuariosRegistrados.map((usuario, index) => (
                            <tr key={index}>
                            <td>{usuario.nombre}</td>
                            <td>{usuario.apellidos}</td>
                            <td>{usuario.email}</td>
                            <td>{usuario.fecha_nacimiento}</td>
                            <td>{usuario.fecha_registro}</td>
                            <td>{usuario.videos_subidos}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    </div>

                    {generatePagination(totalPaginasUR, paginaUsuariosRegistrados, handlePageChangeUR)}
                </Card.Body>
                </Card>
            </Col>

            <Col xs={12} md={12} className="mt-3">
                <Card>
                <Card.Header>Videos almacenados</Card.Header>
                <Card.Body>
                    <div className="table-container">
                    <Table responsive="xl">
                        <thead>
                        <tr>
                            <th>Correo</th>
                            <th>Archivo</th>
                            <th>Fecha de almacenamiento</th>
                        </tr>
                        </thead>
                        <tbody>
                        {videosAlmacenados.map((video, index) => (
                            <tr key={index}>
                            <td>{video.email}</td>
                            <td>{video.archivo}</td>
                            <td>{video.fecha_registro}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    </div>

                    {generatePagination(totalPaginasVA, paginaVideosAlmacenados, handlePageChangeVA)}
                </Card.Body>
                </Card>
            </Col>
            </Row>
        </Container>
        </div>
    );
}

export default Registros;
